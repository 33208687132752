/**@format*/
@tailwind base;
@tailwind components;
@tailwind utilities;
@import './styles/pikaday/PikadayCustom.css';

html {
  min-width: 375px;
}

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply relative rounded-md text-sm mb-0 w-full pt-[23px] pb-[7px] px-3;
    @apply border-transparent placeholder-gray-400 text-gray-900;
  }
  [type='text']:disabled,
  [type='email']:disabled,
  [type='url']:disabled,
  [type='password']:disabled,
  [type='number']:disabled,
  [type='date']:disabled,
  [type='datetime-local']:disabled,
  [type='month']:disabled,
  [type='search']:disabled,
  [type='tel']:disabled,
  [type='time']:disabled,
  [type='week']:disabled,
  [multiple]:disabled,
  textarea:disabled,
  select:disabled {
    @apply bg-gray-50 text-gray-500;
  }
  textarea:focus {
    @apply border-transparent ring-transparent;
  }
  *:focus-visible {
    @apply outline-none ring ring-2 ring-indigo-500;
  }
  ::-ms-reveal {
    display: none;
  }
}

@layer utilities {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button,
  input[type='number'] {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield !important;
  }
  .h-screen-main-container {
    height: calc(100vh - 60px);
  }
  .truncate--text-line {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: normal !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .scrollbar {
    overflow: auto;
  }
  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: transparent;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1f5f9;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: #cbd5e1;
    border-radius: 100vh;
    border: 1px solid #cbd5e1;
  }
}

@layer components {
  @import './components/Button/Button.css';
  @import './styles/till.css';
}
