   /* Till */
  /* Form add new payment card */
  #add-new-card .wpwl-group {
    @apply mb-0 !important;
  }
  #add-new-card .wpwl-form {
    @apply space-y-7 m-0 !important;
  }
  
  #add-new-card .wpwl-wrapper {
    @apply w-full !important;
  }
  #add-new-card .wpwl-label-cardName,
  #add-new-card .wpwl-label-cardNumber,
  #add-new-card .wpwl-label-expiry,
  #add-new-card .wpwl-label-cvv,
  #add-new-card .wpwl-label-cardHolder {
    @apply text-xs
        font-medium
        absolute
        z-20
        left-3
        p-0
        text-gray-900
        w-full
        top-2 !important;
  }
  #add-new-card .wpwl-control-cardName,
  #add-new-card .wpwl-control-cardNumber,
  #add-new-card .wpwl-control-expiry,
  #add-new-card .wpwl-control-cvv,
  #add-new-card .wpwl-control-cardHolder {
    @apply rounded-md
        relative
        block
        h-14
        w-full
        pt-6
        pb-2
        px-3
        border
        border-gray-300
        placeholder-gray-400
        text-gray-900
        outline-none
        focus:outline-none
        focus:z-10
        sm:text-sm
        mb-0
        p-0 !important;
  }
  #add-new-card .wpwl-brand-card {
    @apply absolute z-20 right-3 h-0 w-12 !important;
  }
  #add-new-card .wpwl-brand-VISA {
    @apply bottom-3 h-7 w-12 !important;
  }
  #add-new-card .wpwl-brand-MASTER {
    @apply bottom-2 h-8 w-12 !important;
  }
  #add-new-card .wpwl-brand-AMEX {
    @apply bottom-2 h-8 w-12 !important;
  }
  #add-new-card .wpwl-hint-cardHolderError,
  #add-new-card .wpwl-hint-cardNameError,
  #add-new-card .wpwl-hint-cardNumberError,
  #add-new-card .wpwl-hint-expiryMonthError,
  #add-new-card .wpwl-hint-cvvError {
    @apply absolute z-30 bg-white right-3 top-2 text-red-600 text-xs font-medium m-0 p-0 !important;
  }
  #add-new-card .wpwl-control.wpwl-has-error {
    @apply border border-red-600 !important;
  }
  #add-new-card .wpwl-group-brand {
    @apply hidden !important;
  }

  #add-new-card .wpwl-button-pay {
    @apply py-2.5 px-5 mr-0
        rounded-md
        transition-colors
        text-sm disabled:text-white
        font-medium 
        bg-indigo-600 hover:bg-indigo-700 disabled:bg-indigo-300
        border-0
        opacity-100
        disabled:cursor-not-allowed
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 !important;
  }
  #add-new-card .wpwl-button-cancel {
    @apply py-2.5 px-5
      bg-white border hover:bg-gray-50 
        border-gray-300 
        text-gray-600 disabled:text-gray-400 
        disabled:cursor-not-allowed 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 !important;
  }
  #add-new-card .wpwl-button-back {
    @apply py-2.5 px-5 
        bg-transparent hover:bg-gray-50 !important;
  }
  #add-new-card input[type=checkbox] {
    @apply border border-gray-300 rounded-[4px] checked:text-indigo-600 !important;
  }
  #add-new-card .wpwl-button-disabled {
    @apply bg-indigo-300 hover:bg-indigo-300 text-white cursor-not-allowed !important;
  }
  #add-new-card .wpwl-wrapper-submit {
    @apply flex justify-end items-center 
        mt-0 -mx-6 -mb-5 px-6 py-0 
        h-16 w-[calc(100%+48px)] 
        bg-gray-50 rounded-b-md !important;
  }
  #add-new-card .wpwl-group-saveCard {
    @apply mt-4 mb-7 !important;
  }
  .is-hide-labelName .wpwl-group-cardName,
  .is-hide-labelName .wpwl-group-saveCard {
    display: none !important;
  }
  .required {
    @apply text-red-600 !important;
  }