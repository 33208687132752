/**@format */
@import '~pikaday/css/pikaday.css';

.pika-single {
  @apply absolute shadow-lg bg-white rounded-lg w-fit border border-gray-300 border-solid;
}

.pika-single.date-range {
  @apply static border-none rounded-t-none bg-gray-50 w-full;
}

.pika-single.is-hidden {
  @apply hidden;
}

.pika-lendar {
  @apply float-none w-auto px-5 m-0 h-full;
}

.pika-title {
  @apply flex items-center;
}

.pika-label {
  @apply text-lg font-medium text-gray-900 p-0;
}

.pika-button {
  @apply text-center w-8 h-8;
}

.pika-table {
  @apply mt-4 mb-3;
}

.pika-table th {
  @apply text-sm leading-5 font-normal text-gray-700;
}

.pika-table td.is-selected .pika-button,
.pika-table td.has-event .pika-button {
  @apply bg-indigo-600 rounded-full shadow-none text-white;
}

.pika-table td.is-startrange,
.pika-table td.is-selected.is-startrange {
  @apply rounded-full rounded-r-none bg-indigo-600 shadow-none text-white;
}
.pika-table td.is-endrange,
.pika-table td.is-selected.is-endrange {
  @apply rounded-full rounded-l-none bg-indigo-600 shadow-none text-white;
}

.pika-table td.is-inrange {
  @apply bg-gray-100;
}
.pika-table td.is-inrange .pika-button {
  @apply bg-transparent text-gray-400;
}

.pika-table td.is-startrange .pika-button,
.pika-table td.is-endrange .pika-button {
  @apply bg-transparent shadow-none text-white;
}

.pika-button:hover,
.pika-row.pick-whole-week:hover .pika-button {
  @apply bg-gray-100 rounded-full;
}

.pika-table td .pika-day {
  @apply mx-1.5 my-1 text-sm leading-5 font-medium text-gray-900;
}

.pika-table td.is-outside-current-month .pika-day,
.pika-table td.is-selection-disabled .pika-day {
  @apply text-gray-400;
}
.pika-next:disabled,
.pika-prev:disabled,
.pika-next svg,
.pika-prev svg {
  pointer-events: none;
}
